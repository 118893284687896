import React from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";
import { Link } from "gatsby";

import Grid from "./grid";

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          projects: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categoryId: { eq: "projects" } } }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  subtitle
                }
                fields {
                  slug
                }
              }
            }
          }
          services: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: ASC }
            filter: { frontmatter: { categoryId: { eq: "services" } } }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                }
                fields {
                  slug
                }
              }
            }
          }
          skills: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { frontmatter: { categoryId: { eq: "skills" } } }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <Grid Col="3">
            <div>
              <Link to="/projects/" className="uk-link-heading">
                <h4>Projets et Réalisations</h4>
              </Link>
              <div>
                <ul>
                  {data.projects.edges.map(({ node }) => {
                    return (
                      <li key={node.id}>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                        <small>
                          {" - "}
                          {node.frontmatter.subtitle}
                        </small>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div>
              <Link to="/services/" className="uk-link-heading">
                <h4>Services et Prestations</h4>
              </Link>
              <div>
                <ul>
                  {data.services.edges.map(({ node }) => {
                    return (
                      <li key={node.id}>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div>
              <Link to="/skills/" className="uk-link-heading">
                <h4>Compétences et Techologies</h4>
              </Link>
              <div>
                <ul>
                  {data.skills.edges.map(({ node }) => {
                    return (
                      <li key={node.id}>
                        <Link to={node.fields.slug}>
                          {node.frontmatter.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Grid>
        </div>
      )}
    />
  </div>
);
