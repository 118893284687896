import React from "react";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Sitemap from "../components/sitemap";

const PageTitle = "Plan du site";

export default () => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/sitemap/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>Toutes les pages du site...</div>
      </Section>

      <Section Style="default">
        <Sitemap />
      </Section>
    </Layout>
  </div>
);
